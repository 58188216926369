/* Styles */

@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Amita&display=swap');

html, body {
    width: 100vw;
}

body {
    font-family: 'poppins';
}

/******** Nav bar styles ********/
nav { 
    background-color: #fff;
    border: none;
    height: 70px;
    left: 0;
    margin-bottom: 0;
    padding: 0 30px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
}

nav ul {
    display: flex;
    justify-content: center;
}

.firstnav {
    margin-top: 15%;
}

/******** Wrapper styles ********/
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-name { /* Each section's title */
    color: #efbbcc;
    font-size: 30px;
    font-family: 'Amita', 'poppins';
}

.nav-tab { /* Nav tabs */
    color: #efbbcc;
}

/******** Main page styles  ********/
.section-title {    /* Title font for each section */
    font-size: 35px;
    letter-spacing: 0.15em;
}

.hero-container .hero-text { /* Transition effect for hero text */
    transition-property: all;
    transition-duration: .8s; 
    transition-timing-function: ease;
}

.hero-image {     /* This will show the background image */
    background-color: #3F5088;
    background-size: cover;
    height: 100vh;
    position: relative;
}

.hero-text {               /* This will show text inside the background image */
    text-align: center;
    position: absolute;
    top: 40%;
    left: 65%;
    transform: translate(-50%, -50%);
    color: #fff;
    letter-spacing: 0;
}

.hero-text:hover,    /* These will create a transition effect for the main page's hero text */
.hero-text:active {      /* Styles the hero text pink on hover */
  letter-spacing: 5px;
  color: #fff;
}

.hero-text:after,
.hero-text:before {
  backface-visibility: hidden;
  border: 1px solid rgba(#fff, 0);
  bottom: 0px;
  content: " ";
  display: block;
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
}

.hero-text:hover:after,
.hero-text:hover:before {
  backface-visibility: hidden;
  border-color: #fff;
  transition: width 350ms ease-in-out;
  width: 70%;
}

.hero-text:hover:before {
  bottom: auto;
  top: 0;
  width: 70%;
}

.tech-img {
    width: auto;
    top: 45%;
    left: 35%;
    transform: translate(-50%, -50%);
    position: absolute;
    height: 450px;
}

.my-hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.218);
}

/******** About me styles ********/
.selfie {
    max-width: 250px;
    max-height: 400px;
    margin-bottom: 30px;
}

.about-p {
    line-height: 20px;
}

.tech-list{ 
    font-size: 20px;   
    margin-top: 20px;  
    letter-spacing: 0.15em;
    color: #efbbcc;
} 
  
.tech-list li {   /* Spaces out the listed items in about me */
    line-height: 30px;
}

.resume-btn {
    margin-top: 2%;
}

/******** Modal styles  ********/
input[type=text]:not(.browser-default):focus:not([readonly]) {
    border-bottom: 2px solid pink;
    box-shadow: 0 0 0 0 pink;
}

input[type=email]:not(.browser-default):focus:not([readonly]) {
    border-bottom: 2px solid pink;
    box-shadow: 0 0 0 0 pink;
}

/******** Portfolio styles  ********/
.project-effect {
    box-shadow: 7px 7px 7px #666;
    position : relative;
    transition : all 0.2s;
    top : 0;
}

.proj-btn {
    background-color: white; /* Styles the buttons in the portfolio */
    color: black;
    margin-bottom: 2%;
}

.proj-btn:hover {
    background-color: lightgray;
    color: white;
}

.proj-wrapper {
    max-width : 500px;
    margin-left:auto;
    margin-right:auto;
    display: block;
    position: relative;
    overflow: hidden;
}
  
.proj-content {
    color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 500px;
    background: #cccc;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.4s ease-in-out;
    padding: 5%;
    overflow-y : auto;
}

.proj-content:before {
    opacity: 0.8;
}

.proj-wrapper:hover .proj-content {
    top: 0;
}

.proj-content .proj-btn {
    color: #efbbcc; 
}

/******** Footer styles  ********/
#footer {
    width: 100%;
    text-align: center;
    color: white;
    background-color: white;
  }
  
#footer ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
  
#footer ul li {
    display: inline;
    padding: 0.4em;
}
  
#footer a {
    color: white!important;
}
  
.pinkicon {
    color: pink;
}
  
footer {
    width: 100vw;
    bottom: 0;
    left: 0;
    padding: 1% 5%;
    text-align: center;
    background-color: #fff;
    opacity: 1;
    z-index: 100;
    display: flex;
    flex-direction: column;
}

footer a {
    font-weight: 500;
    text-decoration: none;
}

.social-effect {
    opacity: 0.6;
  }
  
.social-effect:hover {
    opacity: 1;
}

.fa-heart {
    padding: 1%;
}

#footer {
    height: 200px;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #3F5088;
    border-top: 2px solid #576AA9;
}

#footer ul li a i {
    margin-top: 3%;
}

/******** Additional styles  ********/
.my-btn {
    background-color: #efbbcc; 
}

.my-btn:hover {
    background-color: lightgray;
    color: black;
}

.section-title {    /* Title font for each section */
    font-size: 35px;
    letter-spacing: 0.15em;
}

hr {
    border-color: #cdd1cf;
    margin: 30px 0 60px;
}

.underline {
    text-decoration: underline;
}

.font16 {
    font-size: 16px;
}

.anchor {
    display:block;
    padding-top: 100px;
    margin-top: -100px;
}

/* Does not display SVG image on smaller devices */
@media only screen and (max-width : 768px) {
    .tech-img {
        display: none;
    }
    .hero-text {              
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        letter-spacing: 0;
    }
}



